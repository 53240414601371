import { shade } from 'polished'
import styled from 'styled-components'

import { type IBaseButtonProps } from '../BaseButton'
import { userSelectNone } from '@ui/utils/userSelectNone'

export interface ITextButtonStyledProps extends IBaseButtonProps {
  fontSize?: 'body1' | 'body2'
}

export const TextButtonStyled = styled.button<ITextButtonStyledProps>`
  border: none;
  font-style: normal;
  ${(props) =>
    props.fontSize
      ? `font-size: ${props.theme.typography.pxToRem(props.theme.fontSizes[props.fontSize])};`
      : ''}
  font-weight: ${(props) => props.theme.fontWeights.bold};

  background: none;

  display: inline-block;
  padding: ${(props) => props.theme.typography.pxToRem(6)} 0;

  white-space: nowrap;

  cursor: pointer;
  color: ${(props) => props.theme.colors.button.text.content};

  ${userSelectNone()}

  &:hover {
    color: ${(props) =>
      shade(0.5, props.theme.colors.button.text.contentHover)};
  }

  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.button.text.content};
    border: none;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.text.disabled};
  }
`
