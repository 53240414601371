import { type Enum } from '@webapp/utils/enums'

export const PaymentErrorCodeTypes = {
  // Airwallex payout errors
  ACCOUNT_CLOSED: 'ACCOUNT_CLOSED',
  ACCOUNT_FROZEN: 'ACCOUNT_FROZEN',
  BALANCE_INSUFFICIENT: 'BALANCE_INSUFFICIENT',
  BANK_ACCOUNT_RESTRICTED: 'BANK_ACCOUNT_RESTRICTED',
  COULD_NOT_PROCESS: 'COULD_NOT_PROCESS',
  INCORRECT_ACCOUNT_HOLDER_NAME: 'INCORRECT_ACCOUNT_HOLDER_NAME',
  INVALID_ACCOUNT_NUMBER: 'INVALID_ACCOUNT_NUMBER',
  INVALID_CURRENCY: 'INVALID_CURRENCY',
  INCORRECT_BANKING_INFORMATION: 'INCORRECT_BANKING_INFORMATION',
  OTHER: 'OTHER',
  // Airwallex direct debit errors
  REGULATORY_REASON: 'REGULATORY_REASON',
  SYNTAX_ERROR: 'SYNTAX_ERROR',
  INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
  LINKED_BANK_ACCOUNT_INSUFFICIENT_BALANCE:
    'LINKED_BANK_ACCOUNT_INSUFFICIENT_BALANCE',
  CLOSED_ACCOUNT_NUMBER: 'CLOSED_ACCOUNT_NUMBER',
  BLOCKED_AMOUNT: 'BLOCKED_AMOUNT',
  BLOCKED_ACCOUNT: 'BLOCKED_ACCOUNT',
  INVALID_FILE_FORMAT: 'INVALID_FILE_FORMAT',
  SETTLEMENT_FAILED: 'SETTLEMENT_FAILED',
  LIMITED_PARTICIPATION_DFI: 'LIMITED_PARTICIPATION_DFI',
} as const

export type PaymentErrorCodeType = Enum<typeof PaymentErrorCodeTypes>
