import {
  TextButtonStyled,
  type ITextButtonStyledProps,
} from './styles/TextButtonStyled'

export interface ITextButtonProps extends ITextButtonStyledProps {}

export const TextButton: React.FC<ITextButtonProps> = TextButtonStyled

TextButton.displayName = 'TextButton'
